<template>
  <div class="container-md col-lg-8 col-md-8">
    <AddOrganisation @submit="registerChildOrganisation" />
  </div>
</template>

<script>
import AddOrganisation from "@/containers/Organisation/AddOrganisation";
import { mapActions } from "vuex";

export default {
  components: {
    AddOrganisation,
  },
  data() {
    return {
      customer_uid: null,
      parent_org_id: null,
    };
  },
  computed: {
    getCustomerID() {
      const {
        params: { customer_uid: customer_uid },
      } = this.$route;
      let id = customer_uid ? parseInt(customer_uid) : null;
      return id;
    },
    getOrgID() {
      const {
        params: { parent_org_id },
      } = this.$route;
      let id = parent_org_id ? parseInt(parent_org_id) : null;
      return id;
    },
  },
  methods: {
    ...mapActions(["addChildOrganisation"]),
    registerChildOrganisation(data) {
      let payload = {
        ...data,
        ...{ customer_uid: this.getCustomerID, parent_org_id: this.getOrgID },
      };
      this.addChildOrganisation(payload);
    },
  },
};
</script>
